.App {
  text-align: center;
}

body {
  background-color: rgb(15 23 42);
  font-family: "Josefin Sans", sans-serif;
  position: relative;
}

html,
body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  display: none;
}

/* TODO: Ustawić efekt oświetlenia */
/* css below will be fix in the future */
/* ligh-effect component must be also fix */

/* .light-effect {
   fixed inset-0 z-30 transition duration-300 lg:absolute"
  style="background: radial-gradient(600px at 1002px 292px, rgba(29, 78, 216, 0.15), transparent 80%);"
} */

.highlight__about {
  color: rgb(215, 223, 238);
  font-weight: bold;
}

.hovered__element.on-hover {
  color: rgb(94, 234, 212);
}
